.carro-de-compras {
    &__list {
        background: $lightestGray;
        padding: 20px;

        @include mq(sm) {
            padding: 7vw;
        }

        &__title {
            text-align: center;
            color: $black;
            text-transform: uppercase;
            font-family: $titleFont;
            margin: 0;
            padding-bottom: 20px;
            font-size: 24px;

            @include mq(sm) {
                padding-bottom: 3vw;
                font-size: 3vw;
            }
        }
    }

    &__table {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &__heading {
            display: none;

            @include mq(sm) {
                display: flex;
                width: 100%;
                border-top: 1px solid $lightGray;
                border-bottom: 1px solid $lightGray;
                padding: 1vw 0;
            }
        }

        &__cell {
            padding-right: 5px;

            @include mq(sm) {
                padding-right: 3vw;
            }

            &:nth-child(1) {
                width: 30%;

                @include mq(sm) {
                    width: 18%;
                }
            }
            &:nth-child(2) {
                width: 70%;
                padding-left: 10px;
                margin-bottom: 30px;

                @include mq(sm) {
                    width: 23%;
                    padding-left: 0;
                    margin-bottom: 0;
                }
            }
            &:nth-child(3) {
                width: 33.33%;
                text-align: center;

                @include mq(sm) {
                    text-align: none;
                    width: 16%;
                }

                label {
                    text-transform: uppercase;
                    color: $lightGray;
                    font-weight: 100;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(4) {
                width: 33.33%;

                @include mq(sm) {
                    width: 16%;
                }

                label {
                    text-transform: uppercase;
                    color: $lightGray;
                    font-weight: 100;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(5) {
                width: 33.33%;
                text-align: center;

                @include mq(sm) {
                    text-align: none;
                    width: 14%;
                }

                label {
                    text-transform: uppercase;
                    color: $lightGray;
                    font-weight: 100;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            &:nth-child(6) {
                width: 12%;
            }

            p {
                font-size: 12px;
                color: $lightGray;
                text-transform: uppercase;
                margin: 0;

                @include mq(sm) {
                    font-size: 1.2vw;
                }
            }
        }
    }

    &__item {
        flex-wrap: wrap;
        padding: 10px 0;
        display: flex;
        width: 100%;
        border-bottom: 1px solid $lightGray;
        border-top: 1px solid $lightGray;
        position: relative;
        margin-bottom: 30px;

        @include mq(sm) {
            padding: 1vw 0;
            border-top: none;
            margin-bottom: 3vw;
        }

        &__image {
            @include img(140%, $imgwidth: Auto, $imgheight: 100%);

            @include mq(sm) {
                @include img($ratio: 120%);
            }
        }

        &__description {
            padding-right: 15px;

            h3 {
                font-size: 18px;
                margin-top: 0;
                line-height: 1.3;

                @include mq(sm) {
                    font-size: 1.4vw;
                }
            }

            p {
                font-size: 14px;

                @include mq(sm) {
                    font-size: 1.2vw;
                }
            }
        }

        &__price {
            font-size: 14px;
            margin: 0;
            line-height: 1.3;

            @include mq(sm) {
                font-size: 1.4vw;
            }
        }

        &__quantity {
            text-align: center;

            @include mq(sm) {
                text-align: none;
                display: flex;
                align-items: center;
            }
        }

        &__amount {
            font-size: 14px;
            margin: 0;
            line-height: 1.3;

            @include mq(sm) {
                font-size: 1.4vw;
            }
        }

        &__delete-button {
            border: none;
            background: none;
            display: flex;
            cursor: pointer;
            outline: none;
            align-items: center;
            justify-content: flex-end;

            p {
                display: none;

                @include mq(sm) {
                    text-transform: uppercase;
                    color: $vibrantGolden;
                    margin: 0;
                    line-height: 0;
                    font-size: 1vw;
                    display: block;
                }
            }

            i {
                color: $vibrantGolden;
                position: absolute;
                top: 8px;;
                right: 0;

                @include mq(sm)  {
                    position: static;
                    top: auto;
                    right: auto;
                    transform: scale3d(1.5,1.5,1.5);
                    display: inline-block;
                    margin-right: 20px;
                }
            }
        }
    }

    &__cta {
        @include arrow($pointTo: 'up', $size: 10px, $color: $white);

        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background: $white;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        box-shadow: 0 10px 20px $lightGray;
        width: 100%;

        @include mq(sm) {
            @include arrow($pointTo: 'up', $size: 20px, $color: $white);

            width: 50%;
            box-shadow: 0 20px 50px $lightGray;
            padding: 4vw 6vw;
        }

        &__subtotal {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            padding-bottom: 10px;

            @include mq(sm) {
                font-size: 1.4vw;
                padding-bottom: 1vw;
            }

            p {
                margin: 0;
            }
        }

        &__delivery {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            padding-bottom: 10px;

            @include mq(sm) {
                font-size: 1.4vw;
                padding-bottom: 1vw;
            }

            p {
                margin: 0;

                &:last-of-type {
                    text-transform: uppercase;
                }
            }
        }

        &__code {
            border-top: 1px solid $lightGray;
            border-bottom: 1px solid $lightGray;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            color: $vibrantGolden;
            font-size: 12px;
            padding: 10px 0;
            margin-bottom: 10px;
            border-left: none;
            border-right: none;
            outline: none;
            pointer-events: all;
            cursor: pointer;

            @include mq(sm) {
                font-size: 1.2vw;
                padding: 1.5vw 0;
                margin-bottom: 1vw;

            }

            &::placeholder {
                color: $vibrantGolden;
            }

            &:focus::placeholder {
                color:transparent;
            }
        }

        &__total{
            width: 100%;
            display: flex;
            justify-content: space-between;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 14px;
            padding-bottom: 5px;

            @include mq(sm) {
                font-size: 1.6vw;
                padding-bottom: .5vw;
            }
        }

        &__buttons {
            @include trans;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__button {
            @include button;
            width: 100%;
            text-transform: uppercase;
            color: $vibrantGolden;
            border: 1px solid $vibrantGolden;
            background: transparent;
            margin-top: 5px;
            font-size: 12px;
            padding: 10px;

            @include mq(sm) {
                margin-top: 1vw;
                font-size: 1.2vw;
                padding: 1vw;
            }
        }
    }

    &__related {
        margin-left: auto;
        margin-right: auto;
        width: 85%;
        padding: 7vw 15vw;

        &__title {
            text-align: center;
            font-size: 2.5vw;
            text-transform: uppercase;
            font-family: $titleFont;
            padding-bottom: 4vw;
            margin: 0;
        }

        .product {
            &__name,
            &__price {
                @include mq(sm) {
                    font-size: 1.4vw;
                }
            }
        }
    }
}
