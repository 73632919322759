.appoinment {

    &__hero {
        position: relative;
        overflow: hidden;

        &__layer {
            display: none;

            @include mq(sm) {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 2;
                background: transparentize($indigo - #555, .5);
                width: 25vw;
                border-left: .3vw solid $vibrantGolden;
                transform: skew(-20deg);
                left: 85%;
            }
        }

        &__image {
            @include img($ratio: 100%, $imgwidth: auto, $imgheight: 100%);

            img {
                left: 50%;
                transform: translateX(-50%);

                @include mq(sm) {
                    left: 0;
                    transform: translateX(0);
                }
            }

            @include mq(sm) {
                @include img($ratio: 40%);
            }
        }

        &__data {
            background: transparentize($indigo - #555, .5);
            padding: 35px 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq(sm) {
                display: block;
                padding: 0;
                position: absolute;
                z-index: 2;
                width: 35vw;
                top: 16vw;
                text-align: center;
                left: 10vw;
                background: none;
            }

            .content-block {
                &__title {
                    margin-bottom: 0;
                    padding: 0;

                    @include mq(sm) {
                        font-size: 4vw;
                    }

                    &:after {
                        display: none;
                    }
                }

                &__text {
                    font-size: 14px;

                    @include mq(sm) {
                        font-size: 1.5vw;
                        letter-spacing: 1.5px;
                        font-weight: 100;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &__comment {
        text-align: center;
        color: $indigo;
        font-weight: 500;
        padding: 20px;
        font-size: 16px;
        margin: 0;

        @include mq(sm) {
            padding: 4vw 20vw;
            font-size: 1.4vw;
        }
    }

    &__register {

        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $lightestGray;
            flex-direction: column;
            padding: 20px;

            @include mq(sm) {
                padding: 4vw 20vw;
                flex-direction: row;
                justify-content: space-between;

            }
        }

        &__wrapper {
            @include mq(sm) {
                width: 25vw;
            }

            &:first-of-type {
                padding-bottom: 90px;

                @include mq(sm) {
                    padding-bottom: 0;
                }

                &:after {
                    content:'';
                    position: absolute;
                    width: calc(100% - 40px);
                    height: 1px;
                    border-bottom: 1px solid $vibrantGolden;
                    padding-top: 50px;

                    @include mq(sm) {
                        display: none;
                    }
                }
            }

            .content-block {
                &__text {
                    font-weight: 300;
                    font-size: 20px;
                    padding-bottom: 20px;
                    padding-top: 0;

                    @include mq(sm) {
                        padding-bottom: 1vw;
                        font-size: 1.35vw;
                    }
                }

                &__link {
                    color: $vibrantGolden;

                    @include mq(sm) {
                        font-size: 1.2vw;
                    }
                }
            }
        }
    }

    &__form {
        padding: 20px;
        background: $lightestGray;

        @include mq(sm) {
            padding: 6vw 10vw;
        }

        &__container {
            background: $white;

            @include mq(sm) {
                display: flex;
            }
        }

        &__select {
            margin-bottom: 3vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include mq(sm) {
                flex-direction: row;
                align-items: center;
                justify-content: center;

            }
        }

        &__shopper {
            margin-right: 2vw;
            color: $indigo;
            text-align: center;
        }

        &__col {
            @include trans;

            padding: 20px;
            display: flex;
            flex-direction: column;
            min-height: 100vw;

            @include mq(sm) {
                min-height: auto;
                width: 33.34%;
                padding: 2vw;
            }

            &.-active {
                box-shadow: 0 10px 20px $lightGray;
            }
        }

        &__data {
            padding-bottom: 20px;

            @include mq(sm) {
                padding-bottom: 3vw;
            }

            .content-block {
                &__title {
                    font-size: 40px;

                    @include mq(sm) {
                        font-size: 4vw;
                        padding-bottom: 1.5vw;
                        margin-bottom: 1.5vw;
                    }
                }

                &__text {
                    font-weight: 300;
                    font-size: 20px;
                    padding-bottom: 20px;
                    padding-top: 0;

                    @include mq(sm) {
                        padding-bottom: 1vw;
                        font-size: 1.2vw;
                    }
                }
            }
        }

        &__selector,
        &__address {
            border: 1px solid $lightGray;
            margin-bottom: 20px;
            padding: 20px;
            font-size: 18px;
            background: transparent;
            color: $lightGray;

            @include mq(sm) {
                margin-bottom: 1vw;
                padding: 1vw;
                font-size: 1.3vw;
            }

            &--shopper {
                width: 90%;

                @include mq(sm) {
                    width: 20vw;
                }
            }

            &--submit {
                display: block;
                margin-left: auto;
                margin-right: auto;
                border: 1px solid $vibrantGolden;
                color: $vibrantGolden;
                text-transform: uppercase;
                margin-top: 20px;
                width: 90%;

                @include mq(sm) {
                    margin-top: 3vw;
                    padding: 1vw 2vw;
                    width: auto;
                }
            }
        }

        &__text {
            text-align: center;
            font-size: 16px;

            @include mq(sm) {
                font-size: 1.1vw;
            }
        }

        &__registered-address {
            color: $vibrantGolden;
            text-align: center;
            border-bottom: 1px solid $lightGray;
            font-size: 18px;
            padding-bottom: 20px;
            margin-bottom: 20px;

            @include mq(sm) {
                font-size: 1.2vw;
                padding-bottom: 1.5vw;
                margin-bottom: 1.5vw;
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
            padding-bottom: 20px;

            @include mq(sm) {
                padding-bottom: 1.5vw;
            }
        }

        &__input {
            position: absolute;
            left: -9999px;

            &:checked + label {
                    background: $vibrantGolden;
            }
        }

        &__label {
            @include trans;

            position: relative;
            width: 25px;
            height: 25px;
            border: 1px solid $lightGray;
            border-radius: 50%;
        }

        &__info {
            width: 90%;
            padding-left: 10px;
            font-size: 14px;
            @include mq(sm) {
                padding-left: 1vw;
                font-size: 1.2vw;

            }
        }
    }
}
