.benefits {
    overflow: hidden;

    &__hero {
        position: relative;

        @include mq(sm) {
            justify-content: center;
            align-items: center;
        }

        &__container {
            position: relative;
        }

        &__image {
            @include img($ratio:100%, $imgwidth: auto, $imgheight: 100%);

            @include mq(sm) {
                @include img(40%);
            }
        }

        &__layer {
            display: none;

            @include mq(sm) {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 2;
                background: transparentize($indigo - #555, .5);
                width: 25vw;
                border-left: .3vw solid $vibrantGolden;
                transform: skew(-20deg);
                left: 85%;
            }
        }

        &__wrapper {

            @include mq(sm) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                z-index: 5;
            }

            // .play-button {
            //     position: absolute;
            //     top: 60vw;
            //     left: 50%;
            //     transform: translate(-50%) scale3d(3,3,3);

            //     @include mq(sm) {
            //         position: relative;
            //         transform: translate(0) scale3d(1,1,1);
            //         top: auto;
            //         left: auto;
            //     }
            // }
        }

        &__data {
            background: transparentize($indigo - #555, .5);
            padding: 35px 20px;

            @include mq(sm) {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                width: 40vw;
                background: none;
            }

            .content-block {
                &__title {
                    margin-bottom: 0;
                    padding: 0;
                    font-size: 24px;

                    @include mq(sm) {
                        padding-top: 2.5vw;
                        font-size: 2.8vw;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    &__main {
        @include mq(sm) {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 2;
        }

        &__layer {
            position: absolute;
            background: url('../images/home/bottom-left-down.jpg') no-repeat;
            background-size: cover;
            opacity: .2;
            width: 100%;
            height: 100%;
        }

        &__col {
            height: 100vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            @include mq(sm) {
                width: 33.33%;
                height: 34vw;

            }

            .content-block {
                position: relative;
                z-index: 5;

                &__title {
                    @include mq(sm) {
                        font-size: 2vw;
                    }
                }

                &__text {
                    @include mq(sm) {
                        font-size: 1.1vw;
                    }
                }
            }
            // .play-button {
            //     transform: scale3d(1.5,1.5,1.5);

            //     @include mq(sm) {
            //         transform: scale3d(.5,.5,.5);
            //     }
            // }

            &:nth-child(1) {
                background-color: $lightGray;

                .benefits__main__image {
                    background: url('../images/benefits/benefits-comodidad.jpg') no-repeat;
                    background-size: cover;
                }

                .benefits__main__data {
                    padding: 40px 20px;

                    @include mq(sm) {
                        padding: 2vw 6vw;
                    }
                }

            }

            &:nth-child(3) {
                background: url('../images/benefits/benefits-shopping.jpg') no-repeat;
                background-size: cover;
                background-color: $indigo + #555;
                background-blend-mode: multiply;


                .content-block {
                    padding: 0 80px;
                    @include mq(sm) {
                        padding: 1vw 8vw;
                    }

                    &__title {
                        &:after {
                            background: $vibrantGolden;
                        }
                    }

                    &__text {
                        padding-bottom: 30px;

                        @include mq(sm) {
                            padding-bottom: 3vw;
                        }
                    }
                }
            }

            &:nth-child(5) {
                background-color: $lightestGray;

                .benefits__main__image {
                    background: url('../images/benefits/benefits-surtido.jpg') no-repeat;
                    background-size: cover;
                }

                .benefits__main__data {
                    padding: 40px 20px;

                    @include mq(sm) {
                        padding: 2vw 6vw;
                    }
                }
            }

            &:nth-child(7) {
                background: url('../images/benefits/benefits-tienda.jpg') no-repeat center center / cover;

                .content-block {
                    padding: 0 80px;
                    @include mq(sm) {
                        padding: 1vw 8vw;
                    }

                    &__text {
                        padding-bottom: 30px;

                        @include mq(sm) {
                            padding-bottom: 3vw;
                        }
                    }
                }
            }

            &:nth-child(4) {
                background-color: $lightestGray;

                .benefits__main__image {
                    background: url('../images/benefits/benefits-custome-fit.jpg') no-repeat center center /cover;
                }

                .benefits__main__data {
                    padding: 40px 20px;

                    @include mq(sm) {
                        padding: 2vw 6vw;
                    }
                }
            }

            &:nth-child(2) {
            background-color: $lightGray;
            .benefits__main__image {
                background: url('../images/benefits/benefits-asesoria.jpg');
                background-size: cover;
            }

            .benefits__main__data {
                padding: 40px 20px;

                @include mq(sm) {
                    padding: 2vw 6vw;
                }
            }
        }

            &:nth-child(9) {
                background-color: $lightGray;

                .benefits__main__image {
                    background: url('../images/benefits/benefits-relacion.jpg');
                    background-size: cover;
                }

                .benefits__main__data {
                    padding: 40px 20px;

                    @include mq(sm) {
                        padding: 2vw 6vw;
                    }
                }
            }

            &:nth-child(6) {
                background-color: $lightestGray;

                .benefits__main__image {
                    background: url('../images/benefits/mix.png');
                    background-size: cover;
                }

                .benefits__main__data {
                    padding: 40px 20px;

                    @include mq(sm) {
                        padding: 2vw 6vw;
                    }
                }
            }

            &:nth-child(8) {
                background: url('../images/benefits/benefits-satisfaction.jpg') no-repeat;
                background-size: cover;
                background-color: $indigo + #555;
                background-blend-mode: multiply;

                .content-block {
                    padding: 0 80px;

                    @include mq(sm) {
                        padding: 1vw 8vw;
                    }

                    &__title {
                        &:after {
                            background: $vibrantGolden;
                        }
                    }

                    &__text {
                        padding-bottom: 30px;

                        @include mq(sm) {
                            padding-bottom: 3vw;
                        }
                    }
                }
            }
        }

        &__image {
            height: 50%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
        }

        &__data {
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 4vw;
        }
    }
}
