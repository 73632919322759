.product {
    $p:&;
    @include trans;
    box-shadow: 0 2px 5px $lightGray;
    // background: $white;

    @include mq(sm) {
        background: transparent;
    }

    &:hover {
        // background: $white;
        box-shadow: 0 10px 20px $lightGray;
        transform: translateY(-1%) scale3d(1.1,1.1,1.1);

        @include mq(sm) {
            box-shadow: 0 20px 50px $lightGray;
        }

        #{$p}__description {
            transform: translateY(0);
        }
    }

    &__image {
        @include img($ratio: 130%);

        img {
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &__description {
        @include trans;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        background: $white;

        @include mq(sm) {
            padding: 1.5vw;
        }
    }

    &__name {
        font-family: $titleFont;
        font-size: 1.8vw;
        margin: 0;
        text-align: center;
    }

    &__price {
        font-size: 1.8vw;
        color: $vibrantGolden;
    }
}
