.play-button {
    @include trans;
    display: inline-block;
    background: none;
    border:none;
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 50%;

    
    svg {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        width:100%;
        height:100%;
    }

    .circle {
        stroke: $golden;
        stroke-dasharray: 550;
        stroke-dashoffset: 20;
        -webkit-transition: all 0.5s ease-in-out;
        opacity: .7;
    }
    
    .triangle {
      -webkit-transition: all 0.7s ease-in-out;
      stroke-dasharray: 240;
      stroke-dashoffset: 480;
      stroke: $golden;
      transform: translateY(0) 
     }
    
    
    &:hover {
        background: transparentize($fadedIndigo, .5);
      
      .triangle {
        stroke-dashoffset: 0;
        opacity: 1;
        stroke: $vibrantGolden;
        animation: nudge 0.7s ease-in-out;
        
        @keyframes nudge{
          0% {
            transform: translateX(0)  
          }
          30% {
            transform: translateX(-5px)
          }
          50% {
            transform: translateX(5px)
          }
          70% {
            transform: translateX(-2px)
          }
          100% {
            transform: translateX(0)
          }
        }
      }
      
      .circle {
        stroke: $vibrantGolden;
        stroke-dasharray: 650;
        stroke-dashoffset: 0;
        opacity: 1;
      }
      
    }

    &--big {
        width: 100px;
        height: 100px;
    }

    &--white {
        .circle,
        .triangle {
            stroke: $white;
        }

        &:hover {
            .circle,
            .triangle {
                stroke: $white;
            }
        }
    }
}
