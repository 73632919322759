.video-overlay {
    @include trans;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0,.75);
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
    }

    &.-active {
        opacity: 1;
        pointer-events: all;
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 36px;
        height:36px;
        color: $black;
        background: $golden;
        border: none;
        z-index: 10;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-content: center;

        @include mq(md) {
            right: 40px;
            top: 40px;
        }
    }
}