@mixin trans($prop: all, $d: $duration, $e: $easing) {
    transition: $prop $d $e;
}

@mixin title($separator: true, $lineSeparation: 15px, $translate: -50%, $size: $lg, $color: $black, $background: $black, $lineHeight: 1px, $textAlign: center) {
    text-transform: uppercase;
    font-family: $titleFont;
    font-size: $size;
    color: $color;
    position: relative;
    padding-bottom: $lineSeparation;
    font-weight: 400;
    text-align: $textAlign;
    margin-top: 0;

    @if $separator {

        &:after {
            content: "";
            display: block;
            position: absolute;
            height: $lineHeight;
            background: $background;
            left: 50%;
            transform: translateX($translate);
            bottom: 0;
            width: 30px;

            @include mq(sm) {
                width: 3vw;
            }
        }
    }
}

@mixin padd($mode: both, $size: full) {
    @if $size == full {

        @if $mode == both {

            padding-top: 16%;
            padding-bottom: 16%;

            @include mq(sm){
                padding-top: 12%;
                padding-bottom: 12%;
            }

            @include mq(md){
                padding-top: 8%;
                padding-bottom: 8%;
            }
        }

        @if $mode == top {

            padding-top: 16%;

            @include mq(sm){
                padding-top: 12%;
            }

            @include mq(md){
                padding-top: 8%;
            }
        }

        @if $mode == bottom {

            padding-bottom: 16%;

            @include mq(sm){
                padding-bottom: 12%;
            }

            @include mq(md){
                padding-bottom: 8%;
            }
        }
    }

    @if $size == half {
        @if $mode == both {

            padding-top: 8%;
            padding-bottom: 8%;

            @include mq(sm){
                padding-top: 6%;
                padding-bottom: 6%;
            }

            @include mq(md){
                padding-top: 4%;
                padding-bottom: 4%;
            }
        }

        @if $mode == top {

            padding-top: 8%;

            @include mq(sm){
                padding-top: 6%;
            }

            @include mq(md){
                padding-top: 4%;
            }
        }

        @if $mode == bottom {

            padding-bottom: 8%;

            @include mq(sm){
                padding-bottom: 6%;
            }

            @include mq(md){
                padding-bottom: 4%;
            }
        }
    }
}

@mixin overlay($level: .5, $zdex: 2) {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,$level);
    z-index: $zdex;
}

@mixin close($color: $white, $size: 24px) {
    position: absolute;
    display: block;
    width: $size;
    height: $size;
    cursor: pointer;
    transform: rotate(45deg);

    span{
        width: 100%;
        height: 2px;
        position: absolute;
        top:calc(50% - 1px);
        background: $color;
        display: block;

        &:last-child {
            transform: rotate(90deg);
        }
    }
}

@mixin caret($point: up, $size: 20px, $color: $white) {
    width: $size;
    height: $size;
    position: relative;
    display: inline-block;

    &:before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-bottom: 2px solid $color;
        border-left: 2px solid $color;
        left:0;

        @if $point == up {
            top:60%;
            transform: rotate(135deg);
        }

        @if $point == down {
            bottom:10%;
            transform: rotate(-45deg);
        }
    }
}

@mixin button ($fontSize: 1vw, $btnBackground: transparent, $paddTopBottom: .7vw, $paddLeftRight: 1.5vw, $borderColor: $golden, $btnColor: $golden) {
    display: inline-block;
    background: $btnBackground;
    border: 1px solid $borderColor;
    text-transform: uppercase;
    padding-top: $paddTopBottom;
    padding-bottom: $paddTopBottom;
    padding-left: $paddLeftRight;
    padding-right: $paddLeftRight;
    color: $btnColor;
    font-size: $fontSize;
    cursor: pointer;
    text-align: center;

    &:hover {
        transform: scale3d(1.1,1.1,1.1);
        box-shadow: 0 10px 20px rgba(0,0,0,.15);
    }
}

@mixin img($ratio: 56.5%, $width: 100%, $imgheight: auto, $imgwidth: 100%) {
    position: relative;
    width: $width;
    overflow: hidden;
    padding-bottom: $ratio;
    height: 0;

    img {
        position: absolute;
        width: $imgwidth;
        height: $imgheight;
        left: 0;
        top: 0;
    }
}

@mixin text($size: 14px, $color: $indigo, $fontWeight: 300, $fontStyle: normal) {
    font-size: $size;
    color: $color;
    font-weight: $fontWeight;
    font-style: $fontStyle;
}

@mixin arrow($pointTo, $color: inherit, $size: 10px) {
    &:after {
        content: "";
        border: $size solid;
        border-color: $color;
        height: 0;
        width: 0;
        position: absolute;
        z-index: 5;

        @if $pointTo == down {
            border-left-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
            top: 100%;
            left: calc(50% - $size);
        }

        @if $pointTo == up {
            border-left-color: transparent;
            border-right-color: transparent;
            border-top-color: transparent;
            bottom: 100%;
            left: calc(50% - $size);
        }

        @if $pointTo == left {
            border-left-color: transparent;
            border-bottom-color: transparent;
            border-top-color: transparent;
            right: 100%;
            top: calc(50% - $size);
        }

        @if $pointTo == right {
            border-right-color: transparent;
            border-bottom-color: transparent;
            border-top-color: transparent;
            left: 100%;
            top: calc(50% - $size);
        }
    }
}
