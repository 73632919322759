.eg-modal {
    $m: &;

    @include trans;
    position: fixed;
    top: 0;
    bottom: 0;
    left:0;
    right:0;
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    &__backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left:0;
        right:0;
        background: rgba(0,0,0,.75);
    }

    &__container {
        @include trans;

        opacity: 0;
        position: absolute;
        margin: auto;
        top: 50%;
        left: 50%;
        max-width: 900px;
        background: $white;
        transform: translateX(-50%) translateY(-50%);
    }

    &__close {
        @include close($midGray);

        background: none;
        border: none;
        z-index: 100;
        top: -40px;
        right: 0;
        @include mq(sm) {
            top: 20px;
            right: 30px;
        }
    }

    &.-open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        #{$m}__container {
            opacity: 1;
            transform: translateY(-50%) translateX(-50%);
        }
    }
}
