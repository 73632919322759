.brands {

    &__hero {
        position: relative;
        overflow: hidden;

        &__layer {
            display: none;

            @include mq(sm) {
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                z-index: 2;
                background: transparentize($indigo - #555, .5);
                width: 25vw;
                border-left: .3vw solid $vibrantGolden;
                transform: skew(-20deg);
                left: 85%;
            }
        }

        &__slider {
            margin-bottom: -11px !important;

            .slick-dots {
                position: absolute;
                bottom: 2vw;
            }

            .slick-arrow {
                display: none !important;
            }
        }

        &__slide {

            &__image {
                @include img($ratio: 100%, $imgwidth: auto, $imgheight: 100%);

                img {
                    left: 50%;
                    transform: translateX(-50%);

                    @include mq(sm) {
                        left: 0;
                        transform: translateX(0);
                    }
                }

                @include mq(sm) {
                    @include img($ratio: 40%);
                }
            }
        }

        &__data {
            background: transparentize($indigo - #555, .5);
            padding: 35px 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mq(sm) {
                display: block;
                padding: 0;
                position: absolute;
                z-index: 2;
                width: 35vw;
                top: 12vw;
                text-align: center;
                left: 10vw;
                background: none;
            }

            .content-block {
                &__title {
                    margin-bottom: 0;
                    padding: 0;

                    @include mq(sm) {
                        padding-bottom: 1.5vw;
                        font-size: 4vw;
                    }

                    &:after {
                        display: none;
                    }
                }

                &__text {
                    font-size: 14px;

                    @include mq(sm) {
                        font-size: 2vw;
                        letter-spacing: 1.5px;
                        font-weight: 100;
                    }
                }
            }
        }
    }

    &__main {

        &__container {
            display: flex;
            flex-direction: column;

            @include mq(sm) {
                flex-direction: row;
                height: auto;
            }

            &:nth-child(1) {
                background-color: $lightGray;

                .brands__main__image {
                    background: url('../images/brands/austin.jpg') no-repeat;
                    background-size: cover;

                }
            }

            &:nth-child(2) {
                background-color: $lightestGray;

                .content-block {
                    &__title {
                        font-weight: 300;
                        font-size: 32px;

                        @include mq(sm) {
                            font-size: 4.5vw;
                        }
                    }
                }
                .brands__main__image {
                    background: url('../images/brands/cerruti.jpg') no-repeat;
                    background-size: cover;
                    order: 2;

                    @include mq(sm) {
                        order: 1;
                    }
                }

                .brands__main__wrapper {
                    order: 1;

                    @include mq(sm) {
                        order: 2;
                    }
                }
            }

            &:nth-child(3) {
                background-color: $lightGray;

                .content-block {
                    &__title {
                        text-transform: none;
                        font-family: $titleFont;
                        transform: scaleY(1.2);
                    }
                }

                .brands__main__image {
                    background: url('../images/brands/guy-laroche.jpg') no-repeat;
                    background-size: cover;

                }
            }

            .content-block {
                &__title {
                    margin-bottom: 0;
                    font-size: 32px;
                    color: $black;
                    margin-bottom: 10px;

                    @include mq(sm) {
                        font-size: 3.5vw;
                        margin-bottom: auto;
                        margin-bottom: 2vw;
                        padding-bottom: 2.5vw;
                        font-family: $mainFont;
                        letter-spacing: 2px;
                    }
                }

                &__text {
                    font-size: 14px;
                    letter-spacing: 1px;

                    @include mq(sm) {
                        font-size: 1.1vw;
                        margin-bottom: 2vw;
                    }
                }

                &__link {
                    color: $vibrantGolden;
                }
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;

            @include mq(sm) {
                width: 50%;
                flex-basis: auto;
            }
        }

        &__logo {
            width: 50%;
            height: 15vh;
            margin-left: auto;
            margin-right: auto;
            margin-top: 4vw;
            display: flex;
            justify-content: center;

            img {
                height: 100%;
                width: auto;
            }
        }

        &__image {
            flex-basis: 50%;

            @include mq(sm) {
                flex-basis: auto;
                width: 50%;
            }

        }

        &__data {
            padding: 1vw 10vw 4vw 10vw;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}
