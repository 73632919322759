.agradecimiento {
    &__hero  {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        @include mq(sm) {
            display: block;
        }

        &__image {
            background: url('../images/benefits/benefits-relacion.jpg') no-repeat right center / cover;
            height: 100vw;

            @include mq(sm) {
                height: 40vw;
                background: url('../images/gracias/hero.jpg') no-repeat center right / 150%;

            }
        }

        &__data {
            background: $midGray;
            padding: 40px 20px;

            @include mq(sm) {
                padding: 0;
                background: none;
                position: absolute;
                left: 50%;
                top: 35%;
                transform: translate(-50%);
            }
        }

        &__title {
            color: $white;
            margin: 0;
            text-transform: uppercase;
            text-align: center;
            font-family: $titleFont;
            font-size: 28px;

            @include mq(sm) {
                font-size: 4.5vw;
            }
        }
    }

    &__interstitial {
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(sm) {
            padding: 4vw 0;
        }

        &__text {
            text-align: center;
            color: $indigo;
            font-size: 16px;
            font-weight: 500;

            @include mq(sm) {
                width: 65%;
                font-size: 1.4vw;
            }
        }
    }

    &__main {
        background: $lightestGray;
        padding: 20px;

        @include mq(sm) {
            padding: 7vw 8vw;
        }
    }

    &__survey {
        width: 100%;
    }

    &__contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

        @include mq(sm) {
            padding-bottom: 5vw;
        }

        &__question {
            padding-bottom: 25px;

            @include mq(sm) {
                width: 85%;
                justify-content: space-between;
                display: flex;
                padding-bottom: 2vw;
            }
        }

        &__label {
            display: flex;
            align-items: center;
            font-weight: 100;
            text-transform: uppercase;
            color: $vibrantGolden;
            font-size: 16px;
            padding-bottom: 15px;
            margin: 0;

            @include mq(sm) {
                padding-bottom: 0;
                width: 65%;
                padding-right: 1vw;
                font-size: 1.3vw;

            }
        }

        &__options {
            background: transparent;
            padding: 20px;
            font-size: 16px;
            width: 100%;

            @include mq(sm) {
                width: 35%;
                padding: 1.3vw;
                font-size: 1.3vw;
            }
        }

        &__option {
            @include mq(sm) {
                font-size: 1vw;
            }
        }
    }

    &__experience {
        background: $white;
        padding: 20px;
        box-shadow: 0 20px 40px $lightGray;

        @include mq(sm) {
            padding: 7vw 5vw;
            box-shadow: 0 30px 60px $lightGray;

        }

        &__title {
            text-align: center;
            margin: 0;
            font-family: $titleFont;
            text-transform: uppercase;
            color: $indigo;
            padding-bottom: 20px;
            font-size: 20px;

            @include mq(sm) {
                padding-bottom: 2vw;
                font-size: 2.5vw;
            }
        }

        &__question {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid $lightGray;
            padding-bottom: 20px;
            padding-top: 20px;

            @include mq(sm) {
                padding-bottom: 2.5vw;
                padding-top: 2.5vw;
            }

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
            }
        }

        &__satisfaction-level {
            display: flex;
            width: 100%;
            justify-content: space-between;

            @include mq(sm) {
                justify-content: space-around;
                width: 45%;
            }
        }

        &__index {
            font-weight: 100;
            color: $vibrantGolden;
            font-family: $titleFont;
            line-height: .5;
            width: 10%;
            font-size: 30px;

            @include mq(sm) {
                width: 5%;
                font-size: 4vw;
            }
        }

        &__text {
            color: $corpGray;
            width: 90%;
            padding-bottom: 20px;

            @include mq(sm) {
                padding-bottom: 0;
                width: 50%;
                font-size: 1.3vw;
                padding-right: 1vw;
            }

            &--long {
                @include mq(sm) {
                    width: 95%;
                    padding-bottom: 3vw;
                }

                strong {
                    color: $indigo;
                    font-weight: 500;
                }
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            width: 20%;
        }

        &__input {
            position: absolute;
            left: -9999px;

            &:checked + label {
                color: $vibrantGolden;
                border-color: $vibrantGolden;
            }
        }

        &__label {
            @include trans;

            border: 1px solid $lightGray;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $lightGray;
            margin-bottom: 10px;
            height: 40px;
            width: 40px;

            @include mq(sm) {
                margin-bottom: 1vw;
                height: 3.5vw;
                width: 3.5vw;
            }
        }

        &__tag {
            color: $lightGray;
            text-transform: uppercase;
            font-size: 10px;

            @include mq(sm) {
                font-size: .8vw;
            }
        }

        &__data {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mq(sm) {
                padding: 0 10vw;
            }
        }

        &__info {
            width: 100%;
            margin-bottom: 20px;
            padding: 15px 20px;
            background: transparent;
            border: 1px solid $midGray;
            font-size: 14px;

            @include mq(sm) {
                font-size: 1.5vw;
                width: 48%;
                margin-bottom: 2vw;
                padding: 1.2vw 2vw;

            }

            &:nth-child(3),
            &:nth-child(4) {
                @include mq(sm) {
                    margin-bottom: 0;
                }
            }

        }
    }

    &__submit-button {
        margin-top: 50px;
        padding: 15px 0;
        background: transparent;
        border: 1px solid $vibrantGolden;
        color: $vibrantGolden;
        font-size: 16px;
        letter-spacing: 2px;
        text-transform: uppercase;
        width: 100%;
        display: inline-block;

        @include mq(sm) {
            display: block;
            width: auto;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5vw;
            padding: 1.5vw 6vw;
            font-size: 1.3vw;

        }
    }

}
