.header {
    $h: &;

    display: flex;
    position: fixed;
    top: 0;
    left:0;
    right:0;
    z-index: 1000;

    @include mq(md) {
        height: auto;
        background: $white;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        background: $white;
        z-index: 10;
        height: 50px;

        @include mq(md) {
            display: none;
        }
    }

    &:after {
        content: '';
        display: block;
        border: 20px solid $white;
        top: 0;
        transform: skew(-20deg) translateX(50%);
        position: absolute;
        z-index: 10;
        left: 32%;
        bottom: 0;

        @include mq(md) {
            display: none;
        }
    }


    &__logo {
        display: flex;
        align-items: center;
        height: 100%;

        &__wrapper {
            background: url('/images/texture-pattern.jpg');
            padding: 10px 0 10px 15px;
            width: 40%;
            height: 50px;
            position: relative;
            z-index: 10;

            @include mq(md) {
                width: 28%;
                height: auto;
                padding: 1.5vw 0 1.5vw 30px;
                flex-shrink: 0;
            }
        }

        img {
            width: 80%;
            display: inline-block;

            @include mq(md) {
                width: 80%;
                max-width: 350px;
            }
        }
    }

    &__layer {
        display: block;
        border: 20px solid $white;
        top: 0;
        margin-left: -10px;
        transform: skew(-20deg);
        position: relative;
        z-index: 10;

        @include mq(md) {
            border: 2vw solid $white;
            background: $white;
            height: auto;
            width: 2.2vw;
            margin-right: -15px;
            margin-left: -1.5vw;
        }
    }

    &__menu {

        @include mq(md) {
            padding-top: 1vw;
            padding-bottom: 1vw;
            padding-right: 30px;
            padding-left: 30px;
            width: 70vw;
            display: block;
        }

        @include customMq(1400px) {
            padding-top: .75vw;
            padding-bottom: .75vw;
        }

        &__form {
            @include trans;
            position: absolute;
            pointer-events: none;
            opacity: 0;
            transform: translatex(100%);
            // right: 140px;
            top: 48px;

            @include mq(sm) {
                top: auto;
            }

            &-open {
                pointer-events: all;
                transform: translate(0);
                opacity: 1;

                @include mq(sm) {
                    right: 140px;
                    top: auto;
                }
            }
        }

        &__input {
            width: 300px;
            padding: 2.5px 10px;
            border: 1px solid $midGray;

            &::placeholder {
                font-size: 14px;
            }
        }

        &__button {
            background: transparent;
            border: none;
            position: absolute;
            right: 5px;
            top: 3px;
        }

        &__secondary {
            padding: 20px;
            border-bottom: 1px solid $fadedIndigo + #333;

            @include mq(md) {
                padding: 0;
                display: flex;
                height: 50%;
                align-items: center;
                justify-content: flex-end;
                border-bottom: none;
            }
        }

        &__link {
            padding: 0 1vw;
            color: $midGray;
            font-size: 14px;
            margin: 0 0 3vw;
            display: block;

            @include mq(md) {
                margin: 0;
                font-size: 12px;
            }
        }

        &__login-button {
            @include button($paddTopBottom: 5px, $paddLeftRight: 15px, $btnColor: $midGray, $borderColor: $midGray);
            margin: 2.5vw 0 0;
            font-size: 14px;

            @include mq(md) {
                margin: 0 0 0 1vw;
                font-size: 12px;
                width: auto;
            }
        }

        &__primary {
            padding: 20px;

            @include mq(md) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0;
            }
        }

        &__tab {
            display: block;
            text-transform: uppercase;
            font-size: 6vw;
            letter-spacing: 1.4px;
            font-weight: 500;
            margin: 0 0 3vw;
            color: $white;

            &:hover {
                color: $white;
            }

            @include mq(md) {
                font-size: 1.1vw;
                color: $mainColor;
                margin: 1vw 0 0;

                &:hover {
                    color: $linkHover;
                }
            }

            @include customMq(1400px) {
                font-size: 0.95vw;
                margin: 0.7vw 0 0;
            }
        }
    }

    &__nav {
        @include trans;

        opacity: 0;
        transform: translate3d(0, -120%, 0);
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparentize($fadedIndigo, .1);
        border-top: 2px solid $golden;

        @include mq(md) {
            top: 0;
            background: $white;
            opacity: 1;
            transform: translate3d(0,0,0);
            position: relative;
            border-top: none;
            z-index: 10;
            display: flex;
            justify-content: flex-end;
            flex-grow: 2;
            width: 72%;
        }

        &:after {
            content: '';
            display: none;
            border: 20px solid $white;
            top: 0;
            transform: skew(-20deg) translateX(50%);
            position: absolute;
            z-index: 10;
            right: 100%;
            bottom: 0;

            @include mq(md) {
                display: block;
                border: none;
                background: $white;
                height: auto;
                width: 5%;
            }
        }
    }

    &__tienda {
        @include trans;
        position: fixed;
        width: 91%;
        transform: translate3d(100%, 0, 0);
        padding: 20px;
        opacity: 0;
        top: 50px;
        border-top: 2px solid $indigo;
        bottom: 0;
        background: $lightestGray;
        pointer-events: none;

        @include mq(md) {
            position: absolute;
            display: flex;
            top: calc(100% - 1px);
            border-top: none;
            width: 100%;
            padding: 0;
            bottom: auto;
        }

        &__image {
            img {
                position: absolute;
                width: 100%;
                top:0;
                bottom:0;
                height: auto;
            }
        }

        &__aside {
            display: none;

            @include mq(md) {
                display: block;
                position: relative;
                width: 28%;
                overflow: hidden;
            }
        }

        &__main {
            position: relative;

            @include mq(md) {
                width: 72%;
            }

            &:before {
                @include mq(md) {
                    content: '';
                    background: $lightestGray;
                    height: auto;
                    width: 20%;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    transform: skew(-20deg) translateX(40%);
                }
            }
        }

        &__title {
            @include title(false, $textAlign: left, $color: $indigo);
            padding-bottom: 15px;
            margin-bottom: 0;

            @include mq(md) {
                font-size: 3vw;
                padding-bottom: 0;
            }
        }

        &__main-link {
            @include button(16px);
            padding: 7px 15px;

            @include mq(md) {
                @include button(1.2vw);
                padding: .7vw 1.5vw;
            }
        }

        &__close {
            position: absolute;
            right: calc(99% + 20px);
            width: 10%;
            height: 0;
            padding-bottom: 10%;
            background: $lightestGray;
            border-radius: 3px 0 0 3px;
            border:none;
            top: 50%;
            text-align: center;

            i {
                position: absolute;
                top:50%;
                transform: translate3d(-50%, -50%, 0);
                left: 50%;
            }

            @include mq(md) {
                top: 5px;
                right: 10px;
                width: 40px;
                height: 40px;
                padding-bottom: 0;
            }
        }

        &__heading {
            padding-bottom: 15px;
            border-bottom: 1px solid $lightGray;

            @include mq(md) {
                padding: 15px;
                position: relative;
                z-index: 2;
                display: flex;
                justify-content: space-between;
                padding-right: 60px;
                align-items: center;
                padding-top: 40px;
                border-bottom: none;
            }
        }

        &__body {
            max-height: 72vh;
            overflow-y: auto;

            @include mq(md) {
                display: flex;
                padding: 15px;
                justify-content: space-between;
            }
        }

        &__col {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            @include mq(md) {
                width: 48%;
            }
        }

        &__list {
            width: 47%;
            margin-bottom: 10px;

            @include mq(md) {
                position: relative;
                z-index: 2;
            }

            &:nth-child(1) {
                @include mq(md) {
                    padding-bottom: 30px;
                }
            }

            &--long {
                width: 100%;

                .header__tienda__items {
                    columns: 2;
                }
            }
        }

        &__items {
            li {
                margin-bottom: 5px;
            }

            a {
                color: $darkGray;
                font-size: 14px;

                @include mq(md) {
                    font-size: 1.2vw;
                }
            }
        }

        &__category {
            @include title(false, $textAlign: left);
            margin-bottom: 5px;
            padding-top: 5px;
            border-bottom: 1px solid $lightGray;
            padding-bottom: 5px;
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 10;

        @include mq(md) {
            display: none;
        }

        span {
            content: '';
            background: $vibrantGolden;
            border-radius: 2px;
            display: block;
            width: 100%;
            height: 2px;

            @include mq(md) {
                background: $indigo;
            }

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }
    }

    &.-tienda_is-active {
        #{$h}__tienda {
            transform: translate3d(10%, 0, 0);
            opacity: 1;
            pointer-events: all;

            @include mq(md) {
                transform: translate3d(0, 0, 0);
            }
        }

        #{$h}__nav {
            transform: translate3d(-90%, 0, 0);
            opacity: 1;

            @include mq(md) {
                transform: translate3d(0, 0, 0);
            }
        }
    }

    & + main {
        padding-top: 50px;

        @include mq(md) {
            padding-top: 6vw;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
