.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;

    &__left-arrow,
    &__right-arrow {
        background: transparent;
        border: none;
        line-height: 0;
        padding: 0;
    }

    span {
        text-align: center;
        line-height: 0;
        font-size: 16px;
        padding: 0 10px;

        @include mq(sm) {
            font-size: 1.5vw;
            padding: 0 2vw;
        }
    }
}
