$white: #fff;
$black: #000;
$darkestGray: #333;
$darkGray: #787871;
$midGray: #999;
$lightGray: #d2d2d2;
$lightestGray: #f6f6f6;
$indigo: #000972;
$fadedIndigo: #1e1e3d;
$golden: #c9784d;
$vibrantGolden: #cc6d26;
$corpGray: #3c3f46;

$mainColor: $indigo;
$link: $mainColor;
$linkHover: $mainColor + #333;
$text: $white;
$textDark: $black;
$accent: $mainColor;
$bg: $white;
$bgSecondary: $mainColor;
$bgDark: $black;

$base: 40px;
$radius: 20px;

$head: 60px;
$xxl: 36px;
$xl: 24px;
$lg: 21px;
$md: 18px;
$sm: 16px;
$xs: 14px;
$xxs: 12px;

$duration: 0.3s;
$easing: cubic-bezier(.785, .135, .15, .86);
$easingLight: ease;

$mainFont: 'Poppins', sans-serif;
$titleFont: 'Playfair Display', serif;
